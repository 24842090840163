import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <div>
      <Helmet>
        <title>HydraChain - Solving the 'Total Supply Problem'</title>
        <meta name="description" content="Hydra is an open-source Proof-of-Stake blockchain with a unique set of economic features." />
        <meta name="og:title" property="og:title" content="HydraChain - Solving the 'Total Supply Problem'"></meta>
        <meta name="twitter:card" content="HydraChain - Solving the 'Total Supply Problem'"></meta>
        <meta name="og:image" property="og:image" content="https://static.locktrip.com/hydra/staking-economy.jpeg" />
        <link rel="canonical" href="https://hydrachain.org/"></link>
      </Helmet>
    </div>
  );
};

export default SEO;
